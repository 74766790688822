import React, { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";

import SubMenuSubTitle from "../common/SubMenuSubTitle";
import ComponentStyles from "./boat-info.module.css";

export default function BoatInfo(props) {
  return (
    <>
    <Hidden smUp>
      <Grid item xs={1}></Grid>
    </Hidden>
    <Grid item xs={10} sm={4} lg={3}>
      <div className={ComponentStyles.boatInfoGrid}>
        <img
          style={{ maxHeight: "693px", maxWidth: "100%", marginRight: "auto" }}
          src={props.boatImage}
          alt={props.boatAlt}
        />
      
        <div>
          <SubMenuSubTitle info={props.title} />
        </div>
        <div
          style={{
            borderWidth: "1px 0px 1px 0px",
            borderStyle: "solid",
            borderColor: "rgb(250, 70, 22)",
            marginTop: "26.100px",
            marginBottom: "26.100px"
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <div className={ComponentStyles.boatSpecs}>
                Length:
                <br />
                Built:
                <br />
                Origin:
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={ComponentStyles.boatSpecs}>
                {props.specs.length}
                <br />
                {props.specs.built}
                <br />
                {props.specs.origin}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={ComponentStyles.boatSpecContainer}>{props.boatInfoParagraphs.map(paragraph => (
            <p>{paragraph}</p>
          ))}
        </div>
      </div>
    </Grid>
    <Hidden smUp>
      <Grid item xs={1}></Grid>
    </Hidden>
    </>
  );
}
