import React from "react";
import { IconButton, Hidden } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import BackToMainPageArrow from "../common/BackToMainPageArrow";
import ImageComponent from "../common/ImageComponent";
import SubMenuTitle from "../common/SubMenuTitle";
import PrimaryButton from "../common/PrimaryButton";
import Styles from "../common/styles.module.css";
import PageStyles from "./privateLessons.module.css";
import HeroAndNav from "../common/SubPage/HeroAndNav";
import PrivateLessonsMainPhoto2 from "../../images/PrivateLessonsMainPhoto2.jpg";
import PrivateLessonsMainPhoto3 from "../../images/PrivateLessonsMainPhoto3.jpg";
import SchoolOfSailing1967 from "../../images/SchoolOfSailing1967.svg";
import SunHarbourBridge from "../../images/SunHarbourBridge.jpg";
import MiddleSection from "../common/SubPage/MiddleSection";
import Lessons from "../../images/Lessons.jpg";

export default function AboutTheBoats(props) {
  const sectionTitle = "ONE ON \n ONE";
  const rightParagraphs = [
    "Built on the philosophy that the best learning happens by doing, our one-on-one classes put you in conrol of an iconic yacht, with direct guidance and mentoring from Carl.",
    "Working to your schedule, we focus on specific techinique and skills that will develop your understanding of the fundamentals, faster. As we progress we can refine the lessons to cover areas suited to your goals and sailing ambitions."
  ];
  const heroText =
    "Hone your skills with some one on one tutoring with New Zealand's best.";
  const buttonTitle = "SEE THE SCHEDULE";
  const buttonSubTitle = "Book a private lesson today";
  return (
    <Grid container>
      <HeroAndNav
        {...props}
        title={sectionTitle}
        heroImage={SunHarbourBridge}
        heroImageAlt="Harbour Bridge Sunset"
        topImage={SchoolOfSailing1967}
        topImageAlt={"SchoolOfSailing1967 Logo"}
      />
      <MiddleSection
        rightParagraphs={rightParagraphs}
        heroText={heroText}
        buttonTitle={buttonTitle}
        buttonSubTitle={buttonSubTitle}
      />
      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={5} style={{ marginTop: "70px" }}>
        <img
          style={{ width: "500px", height: "333px" }}
          src={PrivateLessonsMainPhoto2}
          alt={"PrivateLessonsMainPhoto2"}
        />
      </Grid>
      <Grid className={PageStyles.seaChangeLogoContainer} item xs={12} sm={6}>
        <ImageComponent type={"svg"} url={"LookUpSeeChange"} />
      </Grid>
      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={5}>
        {" "}
        <img
          style={{ width: "400px", height: "333px" }}
          src={Lessons}
          alt={"Lessons"}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <img
          style={{ width: "500px", height: "333px" }}
          src={PrivateLessonsMainPhoto3}
          alt={"PrivateLessonsMainPhoto3"}
        />
      </Grid>
      <Grid container>
        <Grid item xs={1} sm={6} lg={1}></Grid>
        <Hidden lgUp>
          <Grid item xs={12} sm={6} lg={4} className={PageStyles.descContainer}>
            <p>
              Carl will share insights and techniques from his experiences
              sailing competitively and takling some of the world's most
              challenging seas.
            </p>
            <p>
              To arrange private lessons refer to our schedule and secure the
              dates that work for you
            </p>
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Grid xs={6}></Grid>
        </Hidden>
        <Grid item container xs={12} sm={6} lg={5}>
          {/* <div className={PageStyles.pricingContainer}>
            <Grid container>
              <Grid item xs={5}>
                <div className={PageStyles.pricingText}>Pricing</div>
                <div className={PageStyles.inclText}>EXCL. GST</div>
              </Grid>
              <Grid item xs={6}>
                <div className={PageStyles.lessonText}>1 day lesson</div>
                <div className={PageStyles.costText}>$1350</div>
              </Grid>
            </Grid>
          </div> */}
        </Grid>
        <Hidden lgUp>
          <Grid item xs={6}></Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid item xs={12} sm={6} lg={4} className={PageStyles.descContainer}>
            <p>
              Carl will share insights and techniques from his experiences
              sailing competitively and takling some of the world's most
              challenging seas.
            </p>
            <p>
              To arrange private lessons refer to our schedule and secure the
              dates that work for you
            </p>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
}
