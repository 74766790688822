import React from "react"
import { Typography } from "@material-ui/core"

export default function SubMenuSubTitle(props) {
  return (
    <Typography
      variant={props.variant}
      style={
        props.style === undefined
          ? {
              color: "#FA4616",
              fontFamily: "heavitasregular",
              fontSize: "32px",
              lineHeight: "37px",
              fontWeight: "normal",
            }
          : props.style
      }
    >
      {props.info.split("\n").map(x => {
        return <div>{x}</div>
      })}
    </Typography>
  )
}
