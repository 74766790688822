import React from "react"
import { Grid, Hidden } from "@material-ui/core"

import SubMenuSubTitle from "../common/SubMenuSubTitle"
import Styles from "./about.module.css"
import Carl from "../../images/CarlAndPenny.jpg"
import AboutTheCrewPhoto2 from "../../images/AboutTheCrewPhoto2.jpg"
import AboutTheCrewPhoto3 from "../../images/AboutTheCrewPhoto3.jpg"
import LookupTriangle from "../../images/LookUpTriangle.svg"
import HeroAndNav from "../common/SubPage/HeroAndNav"
import MiddleSection from "../common/SubPage/MiddleSection"

export default function AboutTheCrew(props) {
  const sectionTitle = "SALT IN \n THE BLOOD"
  const rightParagraphs = [
    "A fourth-generation sailor, Carl Whiting has stepped up to lead Whiting Sailing. Carl adds to the family legacy, and has earnt the respect of his peers in his own right. 20 years of professional sailing have taken him around the world and honed his skills.",
    "From embracing the pressures of competition in the America’s Cup and Olympics, through to enduring the gruelling challenges of the Baltic and North Seas, his leadership brings a new generation of experiences to Whiting Sailing.",
  ]

  const CarlAtOlympicDiv = ({ style }) => (
    <div style={style}>
      <img
        style={{ maxHeight: "418px" }}
        src={AboutTheCrewPhoto3}
        alt={"Image of Carl Carrying a rolled sail"}
      />
    </div>
  )

  return (
    <Grid container>
      <HeroAndNav
        {...props}
        title={sectionTitle}
        heroImage={Carl}
        heroImageAlt="Image of Carl Whiting behind the wheel alongside Penny Whiting"
      />
      <MiddleSection
        logo={LookupTriangle}
        logoAlt={"1967 Logo"}
        rightParagraphs={rightParagraphs}
        titleTopRight={["CARL 'TINY'", <br />, "WHITING"]}
      />
      <Hidden mdDown>
        <Grid xs={12} sm={1}></Grid>
      </Hidden>
      <Grid
        style={{
          marginTop: "52px",
        }}
        item
        xs={12}
        sm={5}
        md={5}
      >
        <Hidden smDown>
          <CarlAtOlympicDiv
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              width: "531px",
              height: "520px",
            }}
          />
        </Hidden>
        <Hidden only={["xs", "md", "lg", "xl"]}>
          <CarlAtOlympicDiv
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              height: "520px",
              width: "270px",
            }}
          />
        </Hidden>
      </Grid>
      <Grid
        style={{
          marginTop: "52px",
          width: "531px",
          height: "520px",
        }}
        item
        xs={12}
        sm={6}
      >
        <Hidden only={"xs"}>
          <div>
            <img
              style={{ maxWidth: "531px" }}
              src={AboutTheCrewPhoto2}
              alt={"Image of Carl hanging off the side of a smaller yacht"}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          <div>
            <img
              style={{ padding: "5vw" }}
              src={AboutTheCrewPhoto2}
              alt={"Image of Carl hanging off the side of a smaller yacht"}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          <CarlAtOlympicDiv
            style={{
              padding: "5vw",
            }}
          />
        </Hidden>
        <Hidden only={"xs"}>
          <div>
            <SubMenuSubTitle
              variant={"h5"}
              info={"SALT, SPRAY, \n ROCK, ROLL, \n TRACK, GYBE \n & SAIL."}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          <div style={{ textAlign: "center" }}>
            <SubMenuSubTitle
              variant={"h5"}
              info={"SALT, SPRAY, \n ROCK, ROLL, \n TRACK, GYBE \n & SAIL."}
            />
          </div>
        </Hidden>
      </Grid>
    </Grid>
  )
}
