import React from "react";
import { Typography } from "@material-ui/core";
import Styles from "../common/styles.module.css";

export default function SubMenuTitle(props) {
  return (
    <Typography
      variant="h2"
      component="h2"
      className={[Styles.subMenuTitle, "overideMui"]}
    >
      {props.title.split("\n").map(x => {
        return <div>{x}</div>;
      })}
    </Typography>
  );
}
