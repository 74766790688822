import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import CoursesMain from "../courses/CoursesMain";
import NavPage from "../nav/NavPage";
import SEO from "../seo/seo"

class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  componentDidMount() {
    this.setState({ open: true });
  }

  handleDrawerClose = () => {
    this.setState({ open: false });

    setTimeout(() => {
      this.props.history.push("/");
    }, 801);
  };

  render() {
    return (
      <>
         <SEO 
        title="Learn to Sail | Whiting Sailing | Sail in New Zealand"
        description="Stem onboard on Whiting Sailing keel boat and you're immersed in the realities of sailing. The larger boat allows you to stand alongside your instructor, with in-depth explanations and hands-on coaching at your level.">
        </SEO>
      <NavPage open={this.state.open} handleDrawerOpen={this.handleDrawerClose}>
        <CoursesMain
          handleDrawerClose={this.handleDrawerClose}
          componentName={this.props.componentName}
        />
      </NavPage>
      </>
    );
  }
}

export default withRouter(Courses);
