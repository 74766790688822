import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

import ImageComponent from "../common/ImageComponent";
import LoadingEffect from "./LoadingEffect";
import { Grid, Hidden } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: "100%",
    flexShrink: 0
  },
  drawerPaper: {
    width: "100%",
    overflowX: "hidden",
    background: "#FF3302"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "left",
    marginTop: "200px"
  }
}));

export default function LoadingPage() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    setInterval(function() {
      //TODO: this is a bit hacky - anyone have a better idea?
      // document.getElementById("HomeContainer").style.visibility = "visible";
      setOpen(false);
    }, 3000);
  });

  return (
    <div style={{ float: "right" }}>
    
      <Drawer

        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "50%"
          }}
        >
          <Hidden mdDown>
            <ImageComponent
              type={"svg"}
              url={"WhiteLogo"}
              style={{ width: "40vw", position: "absolute", top: "40vh" }}
              alt="Whiting Sailing School Logo"
            />
          </Hidden>
          <Hidden lgUp>
            <ImageComponent
              type={"svg"}
              url={"WhiteLogo"}
              style={{ width: "90vw", position: "absolute", top: "40vh" }}
              alt="Whiting Sailing School Logo"
            />
          </Hidden>
        </div>
        <div style={{ position: "absolute", bottom: 0 }}>
          <LoadingEffect />
        </div>
      </Drawer>
    </div>
  );
}
