import React from "react";
import Grid from "@material-ui/core/Grid";
import BoatInfo from "../boat-info/BoatInfo";
import StockLandScape from "../../images/StockLandScape.jpg";
import HeroAndNav from "../common/SubPage/HeroAndNav";
import EmotionalRescue from "../../images/EmotionalRescue.jpg";
import EndlessSummer from "../../images/EndlessSummer.jpg";
import MiddleSection from "../common/SubPage/MiddleSection";
import { Hidden } from "@material-ui/core";

export default function ChartersTheBoats(props) {
  const sectionTitle = "A FAMED \n FLEET";
  const rightParagraphs = [
    "Our boats offer the perfect base for all sorts of adventures, and after a hard day’s sailing, there’s a great meal, or an amazing sleep in the comfort of your own bunk room."
  ];
  const heroText =
    "The Whiting Sailing fleet offer a complete sailing experience, without compromising on comfort.";
  const buttonTitle = "SEE THE SCHEDULE";
  const buttonSubTitle = "Charter a boat today";

  return (
    <Grid container>
      <HeroAndNav
        {...props}
        title={sectionTitle}
        heroImage={StockLandScape}
        heroImageAlt="A long-distance shot of a yacht in front of a majestic coastline"
      />
      <MiddleSection
        rightParagraphs={rightParagraphs}
        heroText={heroText}
        buttonTitle={buttonTitle}
        buttonSubTitle={buttonSubTitle}
      />
      <Grid container>
        <Grid item xs={12} sm={1}></Grid>
        <BoatInfo
          boatImage={EmotionalRescue}
          boatAlt={"a shot of ‘emotional rescue’ at sea"}
          title={"Emotional Rescue\n"}
          boatInfoParagraphs={["A Davidson 55, built in the 90’s as the ultimate balance of Offshore Racing capability with cruising comfort, she has competed in some of the biggest international events such as Hawaii's Kenwood Cup.",
          "Her stable and practical layout makes her an ultimate boat for people not only to learn to sail on but to develop further to advanced and club racing levels."]}
          specs={{ length: "55FT", built: "1992", origin: "New Zealand" }}
        />
        <Hidden only={["xs"]}>
          <Grid sm={1}></Grid>
        </Hidden>
        <BoatInfo
          boatImage={EndlessSummer}
          boatAlt={"a shot of ‘endless summer’ at sea"}
          title={"Endless Summer\n"}
          boatInfoParagraphs={["Endless Summer has been the first love of many a novice sailor. She is a Whiting 47, a design chosen for its versatility and purpose built around Penny's teaching experiences.", 
          "The layout ensures that she is equally suitable to be crewed by novices or those seeking to develop their existing skill set."]}
          specs={{ length: "55FT", built: "1992", origin: "New Zealand" }}
        />
      </Grid>
    </Grid>
  );
}
