import React from "react";
import Styles from "./styles.module.css";

export default function ContactInputGroup(props) {
  return (
    <div className={Styles.inputGroup}>
      <input
        onChange={props.setField}
        name={props.label.toLowerCase()}
        className={Styles.textField}
        type={props.type}
        value={props.value}
        required
      ></input>
      <label className={Styles.textFieldLabel}>{props.label}</label>
    </div>
  );
}
