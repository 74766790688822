import React from "react"

export default function ImageComponent(props) {
  return (
    <img
      style={props.style}
      src={require("../../images/" + props.url + "." + props.type)}
      {...props}
    />
  )
}
