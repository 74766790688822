import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import NavPage from "../nav/NavPage";
import SEO from "../seo/seo";
import ChartersCharters from "./ChartersCharters";
import ChartersTheBoats from "./ChartersTheBoats";
import Styles from "./charters.module.css";

const chartersPages = ["Charters", "The Boats", "", ""];

class Charters extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, compassValue: chartersPages[0] };
  }

  componentDidMount() {
    this.setState({ open: true });
  }

  handleDrawerClose = () => {
    this.setState({ open: false });

    setTimeout(() => {
      this.props.history.push("/");
    }, 801);
  };

  handleCompassSelector = value => {
    this.setState({ compassValue: value });
  };

  render() {
    return (
      <NavPage
        open={this.state.open}
        handleDrawerOpen={this.handleDrawerClose}
        classes={Styles}
      >
        <SEO 
        title="Our Boats | Whiting Sailing | Sail in New Zealand"
        description="A personal off-shore sailing experience built for you to go beyond the everyday and discover parts of New Zealand few others see.">
      
        </SEO>
        {this.state.compassValue == "Charters" ? (
          <>

        
            
            <ChartersCharters
              handleDrawerClose={this.handleDrawerClose}
              handleCompassSelector={this.handleCompassSelector}
              componentName={this.props.componentName}
              pageLinks={chartersPages}
              arrowColor={"CompassArrowRed"}
              useCompass={true}
            />
          </>
        ) : (
          <>
        
            <ChartersTheBoats
              handleDrawerClose={this.handleDrawerClose}
              handleCompassSelector={this.handleCompassSelector}
              componentName={this.props.componentName}
              pageLinks={chartersPages}
              arrowColor={"CompassArrowRed"}
              useCompass={true}
            />
          </>
        )}
      </NavPage>
    );
  }
}

export default withRouter(Charters);
