import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import LessonsMain from "./LessonsMain";
import NavPage from "../nav/NavPage";
import Styles from "./privateLessons.module.css";
import SEO from "../seo/seo"

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
    float: "right"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: "96%",
    flexShrink: 0
  },
  drawerPaper: {
    width: "96%",
    background: "#EFEEE8"
  }
}));

class LessonsV2 extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  componentDidMount() {
    this.setState({ open: true });
  }

  handleDrawerClose = () => {
    this.setState({ open: false });

    setTimeout(() => {
      this.props.history.push("/");
    }, 801);
  };

  render() {
    return (
      <>
       <SEO 
        title="Private Sailing Lessons | Whiting Sailing | Sail in New Zealand"
        description="Stem onboard on Whiting Sailing keel boat and you're immersed in the realities of sailing. The larger boat allows you to stand alongside your instructor, with in-depth explanations and hands-on coaching at your level.">
        </SEO>
       
        <NavPage
          open={this.state.open}
          handleDrawerOpen={this.handleDrawerClose}
        >
          <LessonsMain handleDrawerClose={this.handleDrawerClose} />
        </NavPage>
      </>
    );
  }
}

export default withRouter(LessonsV2);
