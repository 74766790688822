import React, { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";

import SubMenuSubTitle from "../common/SubMenuSubTitle";
import BackToMainPageArrow from "../common/BackToMainPageArrow";
import ImageComponent from "../common/ImageComponent";
import SubMenuTitle from "../common/SubMenuTitle";
import PrimaryButton from "../common/PrimaryButton";
import CompassSelector from "../common/CompassSelector";
import CoursesDays from "./CoursesDays";
import Styles from "../common/styles.module.css";
import CoursesStyle from "./courses.module.css";
import GroupSailing from "../../images/GroupSailing.jpg";
import CoursesMainPhoto2 from "../../images/CoursesMainPhoto2.jpg";
import LookUpSeeChange from "../../images/LookUpSeeChange.svg";
import HeroAndNav from "../common/SubPage/HeroAndNav";
import MiddleSection from "../common/SubPage/MiddleSection";

const dayLinkNames = ["Day 1", "Day 2", "Day 3", "Day 4"];
var trainingInfo;
export default function AboutTheBoats(props) {
  const [compassValue, setCompassValue] = useState(dayLinkNames[0]);

  if (compassValue === dayLinkNames[0]) {
    trainingInfo = {
      day: "DAY 1",
      programTitle: "ORIENTATION",
      programInfo1: "We’ll familiarise you with the boat, and those important seamanship skills. We begin with preparation of the boat to set sail, rigging to sail, knot tying and key terminology, arming you with the essentials before getting out on the water to put it into practise.",
      topicCover:
        "Safety, \n Boat orientation, \n Terminology, \n Basic sailing principles, \n Rigging the Boat"
    };
  }
  if (compassValue === dayLinkNames[1]) {
    trainingInfo = {
      day: "DAY 2",
      programTitle: "Sailing Away",
      programInfo1:
        "Put your growing knowledge into practise, as \n we explore sail trimming, points of sail and \n steering. As your understanding grows we \n move into gybing and tacking, familiarising \n you with the fundamentals",
      programInfo2:
        "All of this as we prepare the boat and get out on the \n water to get straight into putting it all to practice.",
      topicCover:
        "Steering and points of Sail, \n Sail trimming, \n Terminology, \n Maneuvering- \n tacking and gybing"
    };
  }
  if (compassValue === dayLinkNames[2]) {
    trainingInfo = {
      day: "DAY 3",
      programTitle: "Immersive Learning",
      programInfo1:
        "Day 3 combines the first two lessons, \n exploring the harbour and applying the \n different skillsets to complete sail changes \n and learn essential emergency manouevres, \n like retrieving a man overboard. ",
      topicCover:
        "Boat handling through maneuvers, \n sail types and changes, \n Terminology, \n sailing as a team"
    };
  }
  if (compassValue === dayLinkNames[3]) {
    trainingInfo = {
      day: "DAY 4",
      programTitle: "Seamanship",
      programInfo1:
        "The final day sees you set sail as a crew. \n From plotting the day’s course, to rigging, \n hoisting the sails and working at all points of \n sail it’s the culmination and application of \n everything you’ve learned, putting your \n competence to the final test.",
      topicCover: "Seamanship, \n Advanced manoeuvres"
    };
  }

  const handleCompassSelector = value => {
    setCompassValue(value);
  };

  const sectionTitle = "CHART YOUR \n COURSE";
  const heroText =
    "A comprehensive, hands-on course to take you from novice to confident sailor.";
  const rightParagraphs = [
    "Stem onboard on Whiting Sailing keel boat and you're immersed in the realities of sailing. The larger boat allows you to stand alongside your instructor, with in-depth explanations and hands-on coaching at your level.",
    "You'll be guided through the essentials, developing your confidence in a safe environment with personal assitance from Carl throughout."
  ];
  const buttonTitle = "Contact Us";
  const buttonSubTitle = "";

  return (
    <Grid container style={{ marginBottom: "15vh" }}>
      <HeroAndNav
        {...props}
        title={sectionTitle}
        heroImage={GroupSailing}
        heroImageAlt="Two girls sitting on a yacht"
        topImage={LookUpSeeChange}
        topImageAlt={"LookUpSeeChange Logo"}
      />
      <MiddleSection
        rightParagraphs={rightParagraphs}
        heroText={heroText}
        buttonTitle={buttonTitle}
        buttonSubTitle={buttonSubTitle}
      />
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          background: "#FFC1B3",
          marginTop: "70px",
          paddingBottom: "70px"
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={1}></Grid>
          <Hidden smUp>
            {" "}
            <Grid
              item
              xs={12}
              sm={5}
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <CompassSelector
                handleCompassSelector={props.handleCompassSelector}
                componentName={props.componentName}
                handleCompassSelector={handleCompassSelector}
                subPages={dayLinkNames}
                arrowColor={"CompassArrowRed"}
              />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12} sm={6} style={{ marginTop: "70px" }}>
              <img src={CoursesMainPhoto2} alt="CoursesMainPhoto2" />
            </Grid>
          </Hidden>
          <Hidden only={"xs"}>
            {" "}
            <Grid item xs={12} sm={5}>
              <CompassSelector
                handleCompassSelector={props.handleCompassSelector}
                componentName={props.componentName}
                handleCompassSelector={handleCompassSelector}
                subPages={dayLinkNames}
                arrowColor={"CompassArrowRed"}
              />
            </Grid>
          </Hidden>
          <Hidden only={"xs"}>
            <Grid item xs={12} sm={6} style={{ marginTop: "70px" }}>
              <img src={CoursesMainPhoto2} alt="CoursesMainPhoto2" />
            </Grid>
          </Hidden>
          <CoursesDays day={trainingInfo} />
        </Grid>
      </Grid>
      <Hidden only={"xs"}>
        {" "}
        <Grid item xs={1}></Grid>
      </Hidden>
       {/*<Grid item xs={12} sm={10} style={{ marginTop: "10vh" }}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            border: "2px solid red",
            padding: "30px 0 30px 0"
          }}
        >
       
          <Grid
            item
            xs={12}
            sm={12}
            lg={3}
            className={CoursesStyle.practicalLessonsGrid}
          >
            <Hidden mdDown>
              <SubMenuSubTitle info={"4 x 3hr \n Practical \n Lessons"} />
            </Hidden>
            <Hidden mdUp>
              <SubMenuSubTitle info={"4 x 3hr Practical Lessons"} />
            </Hidden>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            lg={4}
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div>
              <div className={Styles.lessonHeader}>WEEKEND COURSE</div>
              <div className={Styles.lessonDescription}>
                Saturday & Sunday afternoons over {"\n"} 2 weekends from 12:30pm
                - 3:30pm
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {" "}
            <Hidden smUp>
              {" "}
              <ImageComponent
                style={{
                  position: "relative",
                  marginBottom: 0,
                  transform: "rotate(90deg)",
                  width: "1.5vw"
                }}
                type={"svg"}
                url={"Or"}
              />
            </Hidden>
            <Hidden only={"xs"}>
              {" "}
              <ImageComponent
                style={{ position: "relative", marginBottom: 0 }}
                type={"svg"}
                url={"Or"}
              />
            </Hidden>
            <div className={CoursesStyle.circle}>or</div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            lg={4}
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div>
              <div className={Styles.lessonHeader}>WEEKDAY COURSE</div>
              <div className={Styles.lessonDescription}>
                Monday to Thursday afternoons{"\n"} from 3:30pm to 6:30pm
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Hidden only={"xs"}>
        {" "}
        <Grid item xs={1}></Grid>
      </Hidden>
      <Grid container style={{ marginBottom: "70px" }}>
        <Hidden only={"xs"}>
          {" "}
          <Grid item xs={1}></Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={10}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Grid
            container
            style={{
              border: "2px solid red",
              padding: "30px 0 30px 0",
              borderTop: "0"
            }}
          >
            <Hidden only={["xs", "md", "lg", "xl"]}>
              <Grid item xs={1}></Grid>
            </Hidden>
            <Grid
              item
              xs={6}
              sm={5}
              lg={3}
              style={{
                display: "flex",
                justifyContent: "flex-start"
              }}
            >
              {" "}
              <SubMenuSubTitle info={"PRICING"} />
            </Grid>
            <Grid
              item
              xs={6}
              sm={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1vh"
              }}
            >
              <Grid container>
                <Grid
                  xs={12}
                  style={{
                    fontFamily: "heavitasregular",
                    color: "#FA4616",
                    fontSize: "32px",
                    lineHeight: "16px",
                    fontWeight: "normal",
                    marginBottom: "2vh",
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  $899PP
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    fontFamily: "heavitasregular",
                    color: "#FA4616",
                    fontSize: "11px",
                    lineHeight: "11px",
                    fontWeight: "normal",
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  INCL. GST
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={1}></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={3}
              className={CoursesStyle.practicalLessonsButtonGrid}
            >
              <div className={CoursesStyle.courseButtonGrid}>
                {" "}
                <PrimaryButton
                  className={CoursesStyle.courseButton}
                  title={"BECOME SEA-WORTHY"}
                  subtitle={"Book a course today"}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
       
      </Grid>
    </Grid>
     */ }
     </Grid>
  );
}
