import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import Styles from "./styles.module.css";
import CommonStyles from "../styles.module.css";
import PrimaryButton from "../../common/PrimaryButton";
import { Link } from "react-router-dom";
export default function MiddleSection(props) {
  const logo = props.logo;
  const logoAlt = props.logoAlt;
  const rightParagraphs = props.rightParagraphs;
  const titleTopRight = props.titleTopRight;
  const heroText = props.heroText;
  const buttonTitle = "Contact Us";
  const buttonSubTitle = "";

  return (
    <>
      <Grid item xs={12} sm={1}></Grid>
      {logo && (
        <Hidden only={"xs"}>
          <Grid item xs={12} sm={4} md={5}>
            <img
              style={{
                display: "flex",
                alignItems: "center"
              }}
              src={logo}
              alt={logoAlt}
            />
          </Grid>
        </Hidden>
      )}
      {heroText && (
        <>
          <Hidden smUp>
            {" "}
            <Grid item xs={1}></Grid>
          </Hidden>
          <Grid item xs={10} sm={4}>
            <div
              className={CommonStyles.heroText}
              style={{ marginBottom: "50px" }}
            >
              {heroText}
            </div>
            <Hidden only={"xs"}>
              {" "}
              {buttonTitle && (
                <Link to={"/contact"}>
                <PrimaryButton title={buttonTitle} subtitle={buttonSubTitle} />
                </Link>
              )}
            </Hidden>
          </Grid>
          <Hidden smUp>
            {" "}
            <Grid item xs={1}></Grid>
          </Hidden>
          <Grid item xs={12} sm={1}></Grid>
        </>
      )}
      <Hidden smUp>
        {" "}
        <Grid item xs={1}></Grid>
      </Hidden>
      <Grid item xs={10} sm={6}>
        {titleTopRight && (
          <div className={Styles.aboutHeader}>{titleTopRight}</div>
        )}
        <div className={Styles.aboutDescription}>
          {rightParagraphs.map(paragraph => (
            <p>{paragraph}</p>
          ))}
        </div>
      </Grid>
      <Hidden smUp>
        {" "}
        <Grid item xs={1}></Grid>
      </Hidden>
    </>
  );
}
