import React from "react";
import { IconButton } from "@material-ui/core";
import { Grid, Hidden } from "@material-ui/core";

import ImageComponent from "../common/ImageComponent";
import PrimaryButton from "../common/PrimaryButton";
import ChartersChartersPhoto2 from "../../images/ChartersChartersPhoto2.jpg";
import ChartersChartersPhoto3 from "../../images/ChartersChartersPhoto3.jpg";
import ChartersChartersPhoto4 from "../../images/ChartersChartersPhoto4.jpg";
import HeroAndNav from "../common/SubPage/HeroAndNav";
import MiddleSection from "../common/SubPage/MiddleSection";

import Styles from "../common/styles.module.css";
import PageStyles from "./charters.module.css";
import SailingBoat from "../../images/SailingBoat.jpg";

export default function ChartersCharters(props) {
  const sectionTitle = "ESCAPE \n ORDINARY";
  const rightParagraphs = [
    "Whether it's exploring untouched bays or disconnecting from the everyday, pushing the limits and letting the wind decide your course, a Whiting Sailing charter is beyond ordinary",
    "We embrace your sense of adventure, creating a uniquely personalexperience unlike anything else on offer in New Zealand. No two have ever been the same, and they never will be.",
    "Everything is provided from food and drinks to sailing gear. With an internationally seasoned professional sailor to keep you on course, all you need to bring is yourself and your coastal sailing ambitions."
  ];
  const heroText =
    "Over 50 years experience shaping the future of sailing and developing confident New Zealand sailors.";
  const buttonTitle = "SEE THE SCHEDULE";
  const buttonSubTitle = "Charter a boat today";

  return (
    <Grid container>
      <HeroAndNav
        {...props}
        title={sectionTitle}
        heroImage={SailingBoat}
        heroImageAlt="Sailing Boat"
      />
      <MiddleSection
        rightParagraphs={rightParagraphs}
        heroText={heroText}
        buttonTitle={buttonTitle}
        buttonSubTitle={buttonSubTitle}
      />
      <Hidden smUp>
        <Grid item xs={12}>
          <ImageComponent type={"svg"} url={"LookUpCircleW"} style={{paddingLeft:"5vw", paddingRight: "5vw", marginTop: "2vh"}} />
        </Grid>
      </Hidden>
      <Grid container style={{ marginTop: "20vh" }}>
        <Grid item xs={12} sm={1}></Grid>
        <Hidden smUp>
          {" "}
          <Grid item xs={1}></Grid>
        </Hidden>
        <Grid item xs={10} sm={5}>
          <Grid item xs={12}>
            <img
              className={PageStyles.charterPhotoTwo}
              src={ChartersChartersPhoto2}
              alt={"ChartersChartersPhoto2"}
            />
          </Grid>
        </Grid>
        <Hidden smUp>
          {" "}
          <Grid item xs={1}></Grid>
        </Hidden>
        <Hidden smUp>
          {" "}
          <Grid item xs={1}></Grid>
        </Hidden>
        <Grid item xs={10} sm={6}>
          <Hidden only={"xs"}>
            {" "}
            <ImageComponent type={"svg"} url={"LookUpCircleW"} />
          </Hidden>
          <Grid item xs={12}>
            <img
              className={PageStyles.charterPhotoThree}
              src={ChartersChartersPhoto3}
              alt={"ChartersChartersPhoto3"}
            />
          </Grid>
        </Grid>
        <Hidden smUp>
          {" "}
          <Grid item xs={1}></Grid>
        </Hidden>
        <div style={{ position: "relative", top: "-50%", left: "45%" }}>
          <ImageComponent type={"svg"} url={"LookUp1967"} />
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={1}></Grid>
        <Hidden smUp>
          {" "}
          <Grid item xs={1}></Grid>
        </Hidden>
        <Grid
          item
          xs={10}
          sm={5}
          style={{
            maxHeight: "20vh"
          }}
        >
          <Grid item xs={12}>
            <ImageComponent type={"svg"} url={"Est1"} />.
          </Grid>
          <Grid xs={12}>
            <div className={Styles.courseDescription}>
              Our Off-Shore Experiences have limited{"\n"}
              availability. Refer to our schedule and secure the{"\n"}
              dates that work best for you.
            </div>
          </Grid>
          <Hidden smUp>
            {" "}
            <Grid item xs={1}></Grid>
          </Hidden>
          <Hidden only={"xs"}>
            {" "}
            <Grid xs={12}>
              <div style={{ marginTop: "90px" }}>
                <PrimaryButton
                  title={"SEE THE SCHEDULE"}
                  subtitle={"Charter a boat today"}
                />
              </div>
            </Grid>
          </Hidden>
        </Grid>
        <Hidden smUp>
          {" "}
          <Grid item xs={1}></Grid>
        </Hidden>
        <Hidden smUp>
          {" "}
          <Grid item xs={1}></Grid>
        </Hidden>
        <Grid
          item
          xs={10}
          sm={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Hidden smUp>
            {" "}
            <img
              src={ChartersChartersPhoto4}
              alt={"ChartersChartersPhoto4"}
              style={{ width: "100%" }}
            />
          </Hidden>
          <Hidden only={"xs"}>
            {" "}
            <img
              src={ChartersChartersPhoto4}
              alt={"ChartersChartersPhoto4"}
              style={{ maxWidth: "400px" }}
            />
          </Hidden>
        </Grid>
        <ImageComponent
          style={{ position: "relative", bottom: "15%", left: "85%" }}
          type={"svg"}
          url={"LookUp3Since"}
        />
        <Hidden smUp>
          {" "}
          <Grid xs={12}>
            <div>
              <PrimaryButton
                title={"SEE THE SCHEDULE"}
                subtitle={"Charter a boat today"}
              />
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
}
