import React from "react";
import Grid from "@material-ui/core/Grid";

import SubMenuSubTitle from "../common/SubMenuSubTitle";
import Styles from "../common/styles.module.css";
import CoursesStyle from "./courses.module.css";

export default function CoursesDays(props) {
  return (
    <>
      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={5} className={CoursesStyle.trainingInfo}>
        <SubMenuSubTitle
          style={{
            fontFamily: "heavitasregular",
            color: "#FA4616",
            fontSize: "20px",
            lineHeight: "20px",
            fontWeight: "normal"
          }}
          info={props.day.day}
        />
        <SubMenuSubTitle info={props.day.programTitle} />
        <div className={Styles.dayDescription}>{props.day.programInfo1}</div>
      </Grid>
      <Grid item xs={12} sm={6} className={CoursesStyle.topicCovers}>
        <div className={Styles.topicsCoveredHeader}>Topics Covered</div>
        <div className={Styles.topicsCoveredList}>{props.day.topicCover}</div>
      </Grid>
    </>
  );
}
