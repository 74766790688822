import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContactMain from "./ContactMain";
import Styles from "./styles.module.css";
import HamburgerBack from "../common/HamburgerBack";
import SEO from "../seo/seo";
import NavPage from "../nav/NavPage";

class ContactV2 extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  componentDidMount() {
    this.setState({ open: true });
  }

  handleDrawerClose = () => {
    // this.props.handleDrawerOpen(true);
    // this.props.handleRender(false);
    this.setState({ open: false });
    // this.props.history.push("/");

    setTimeout(() => {
      this.props.history.push("/");
    }, 801);
  };

  render() {
    return (
      <>
        <SEO 
        title="Contact Us | Whiting Sailing | Sail in New Zealand"
        description="To find out more about a Charter or Sailing lessons with Whiting Sailing get in touch and we’ll be happy to help.">
      
        </SEO>
        <NavPage
          open={this.state.open}
          // handleDrawerOpen={this.handleDrawerClose}
        >
          <ContactMain handleDrawerClose={this.handleDrawerClose} />
        </NavPage>
      </>
    );
  }
}

export default withRouter(ContactV2);
