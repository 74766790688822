
const ROUTES = {
  POST_CONTACT_FORM: `${process.env.REACT_APP_API_BASE_URL}/contact`
}

const getUrl =  (path)  => {
  const outputProtocol = window.location.protocol.replace(/:$/, "");
  return `${outputProtocol}://${window.location.hostname}:${window.location.port}${path}`;
}

export const postContactForm = (data) => {
  const url = getUrl(ROUTES.POST_CONTACT_FORM)
  return fetch(url, {
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
  });

}
