import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Styles from "./PrimaryButton.module.css";
import { blockStatement } from "@babel/types";

const ColorButton = withStyles(theme => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: "#FA4616",
    borderRadius: "0",
    fontFamily: "sofia-pro",
    minWidth: "294px",
    boxShadow: "0px 0px 0px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    "&:hover": {
      background: "#FF6347"
    },
    display: "inline-block"
  }
}))(Button);

export default function PrimaryButton(props) {
  return (
    <ColorButton variant="contained">
      <div>
        <span className={Styles.title}>{props.title}</span> <br />
        <div style={{ fontSize: "x-small" }}>{props.subtitle}</div>
      </div>
    </ColorButton>
  );
}
