import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Styles from "./styles.module.css";

import ContactInputGroup from "./ContactInputGroup";
import * as Yup from "yup";
import * as api from "../../services/api";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Please enter a valid name")
    .required("Please enter a valid name"),
  message: Yup.string()
    .min(5, "Please enter a valid message")
    .required("Please enter a valid message"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter a valid email address"),
  phone: Yup.string()
    .min(3, "Please enter a valid phone number")
    .required("Please enter a valid phone number"),
});

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: "rgb(255, 193, 179)",
    border: "1px solid rgb(255, 193, 179)",
    padding: "10px",
    fontFamily: 'sofia-pro',
    color: "red",
    margin: "0px 0px 30px 0px"
  },
  success: {
    backgroundColor: "rgb(140 206 142 / 42%)",
    border: "1px solid rgb(140 206 142 / 42%)",
    padding: "10px",
   
    fontFamily: 'sofia-pro',
    color: "green",
    margin: "0px 0px 30px 0px"
  },

  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    borderBottom: "1px solid #fa4616",
  },
}));

const DEFAULT_FORM = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const SUCCESS_MESSAGE = `Thanks for getting in touch with Whiting Sailing. Our crew will get back to you
as soon as possible! If your request is urgent, please call Jasmine on <a href="tel:+6421922834">+64 21 922 834</a>`;

const ERROR_INVALID_FIELDS_MESSAGE = `Please check if all form fields are valid.`;

const ERROR_MESSAGE = `Sorry, something went wrong. Please try again or contact us at <a href="mailto:info@whitingsailing.com">info@whitingsailing.com</a> or give us a call at +64 21 922 834`;

export default function TextFields() {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [data, setData] = useState(DEFAULT_FORM);

  const setField = useCallback(
    (field) => {
      if (field.target) {
        setData({
          ...data,
          [field.target.name]: field.target.value,
        });
      }
    },
    [data]
  );

  //validateform
  const validate = (data) => {
    return new Promise((resolve, reject) => {
      validationSchema
        .validate(data)
        .then(() => {
          resolve({});
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleResponse = response => {
    switch (response.status) {
      case 200: {
        setSuccess(SUCCESS_MESSAGE);
        setError("");
        break;
      }

      case 400 : {
        setSuccess("");
        setError(ERROR_INVALID_FIELDS_MESSAGE);
        break;
      }

      case 500: {
        setSuccess("");
        setError(ERROR_MESSAGE);
        break;
      }
      
      default: {
        setSuccess("");
        setError(ERROR_MESSAGE);
      }
    }

  }

  const submitForm = (e, data) => {
    setSuccess("");
    e.preventDefault();
    validate(data)
      .then((_) => {
        api.postContactForm(data)
          .then((response) => 
          handleResponse(response)
          )
          .catch((error) => {
            setSuccess("");
            setError(ERROR_MESSAGE);
          });
      })
      .catch((validationError) => {
        setError(validationError.message);
      });
  };

  return (
    <form
      onSubmit={(e) => submitForm(e, data)}
      className={classes.container}
      method="POST"
    >
      {error && <div dangerouslySetInnerHTML={ {__html: error}} className={classes.error}></div>}
      {success && <div dangerouslySetInnerHTML={{__html: success}} className={classes.success}></div>}
      <ContactInputGroup
        value={data.name}
        setField={setField}
        label={"Name"}
        type={"text"}
      ></ContactInputGroup>
      <ContactInputGroup
        value={data.email}
        setField={setField}
        label={"Email"}
        type={"email"}
      ></ContactInputGroup>
      <ContactInputGroup
        value={data.phone}
        setField={setField}
        label={"Phone"}
        type={"tel"}
      ></ContactInputGroup>
      <ContactInputGroup
        value={data.message}
        setField={setField}
        label={"Message"}
        type={"text"}
      ></ContactInputGroup>

      <div className={Styles.formButtonGrid}>
        <input className={Styles.formButton} type="submit"></input>
      </div>
    </form>
  );
}
