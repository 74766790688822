import React from "react"
import CompassSelectorArrowDirection from "./CompassSelectorArrowDirection"

export default function CompassSelector(props) {
  return (
    <CompassSelectorArrowDirection
      subPages={props.subPages}
      handleCompassSelector={props.handleCompassSelector}
      arrowColor={props.arrowColor}
    />
  )
}
