import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import Styles from "./about.module.css";
import HamburgerBack from "../common/HamburgerBack";
import AboutTheLegacy from "./AboutTheLegacy";
import AboutTheBoats from "./AboutTheBoats";
import AboutTheCrew from "./AboutTheCrew";
import NavPage from "../nav/NavPage";
import SEO from "../seo/seo"

const aboutPageLinkNames = ["The Crew", "", "The Boats", "The Legacy"];
class About extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, compassValue: aboutPageLinkNames[0] };
  }

  componentDidMount() {
    this.setState({ open: true });
  }

  handleDrawerClose = () => {
    this.setState({ open: false });

    setTimeout(() => {
      this.props.history.push("/");
    }, 801);
  };

  handleCompassSelector = value => {
    this.setState({ compassValue: value });
  };

  render() {
    return (
      <>
      <SEO 
      title="About Us | Whiting Sailing | Sail in New Zealand"
      description="Stem onboard on Whiting Sailing keel boat and you're immersed in the realities of sailing. The larger boat allows you to stand alongside your instructor, with in-depth explanations and hands-on coaching at your level.">
      </SEO>
      <NavPage
        open={this.state.open}
        handleDrawerOpen={this.handleDrawerClose}
        classes={Styles}
      >
        {this.state.compassValue == "The Boats" ? (
          <>
            
            <AboutTheBoats
              handleDrawerClose={this.handleDrawerClose}
              handleCompassSelector={this.handleCompassSelector}
              componentName={this.props.componentName}
              pageLinks={aboutPageLinkNames}
              arrowColor={"CompassArrowWhite"}
              useCompass={true}
            ></AboutTheBoats>
          </>
        ) : this.state.compassValue == "The Crew" ? (
          <>
           
            <AboutTheCrew
              handleDrawerClose={this.handleDrawerClose}
              handleCompassSelector={this.handleCompassSelector}
              componentName={this.props.componentName}
              pageLinks={aboutPageLinkNames}
              arrowColor={"CompassArrowWhite"}
              useCompass={true}
            ></AboutTheCrew>
          </>
        ) : this.state.compassValue == "The Legacy" ? (
          <>
           
            <AboutTheLegacy
              handleDrawerClose={this.handleDrawerClose}
              handleCompassSelector={this.handleCompassSelector}
              componentName={this.props.componentName}
              pageLinks={aboutPageLinkNames}
              arrowColor={"CompassArrowWhite"}
              useCompass={true}
            ></AboutTheLegacy>
          </>
        ) : null}
      </NavPage>
      </>
    );
  }
}

export default withRouter(About);
