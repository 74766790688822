import React, { Component } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";

import NavDrawerV2 from "./nav/NavDrawer.v2";
import ContactV2 from "./contact/Contact.v2";
import LessonsV2 from "./lessons/Lessons.v2";
import LoadingPage from "./loading/LoadingPage";
import LandingV3 from "./landing/Landing.v3";
import About from "./about/About";
import ComingSoon from "./coming-soon/ComingSoon";
import Charters from "./charters/Charters";
import Courses from "./courses/Courses";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navDrawerOpen: false,
      navPage: null,
      viewState: 0,
      playLoadingEffect: true,
      shouldRender: true
    };
  }

  turnOffLoadingEffect = () => {
    if (this.state.playLoadingEffect != false)
      this.setState({ playLoadingEffect: false });
  };

  handleDrawerOpen = keepOpen => {
    if (keepOpen === true) {
      this.setState({ navDrawerOpen: keepOpen });
      return;
    }
    const open = !this.state.navDrawerOpen;
    this.setState({ navDrawerOpen: open });
  };

  getNavPage = page => {
 
    if (page != this.state.navPage)
      this.setState({ navPage: page, navDrawerOpen: true });

    let element = null;

    switch (page) {
      case "contact":
        element = (
          <ContactV2
            open={true}
            setPage={this.setPage}
            handleDrawerOpen={this.handleDrawerOpen}
          ></ContactV2>
        );
        break;
      // case "charters":
      //   element = (
      //     <Charters
      //       open={true}
      //       setPage={this.setPage}
      //       handleDrawerOpen={this.handleDrawerOpen}
      //     ></Charters>
      //   );
      //   break;
      // case "courses":
      //   element = (
      //     <Courses
      //       open={true}
      //       setPage={this.setPage}
      //       handleDrawerOpen={this.handleDrawerOpen}
      //     ></Courses>
      //   );
      //   break;
      // case "lessons":
      //   element = (
      //     <LessonsV2
      //       open={true}
      //       setPage={this.setPage}
      //       handleDrawerOpen={this.handleDrawerOpen}
      //     ></LessonsV2>
      //   );
      //   break;
      // case "about":
      //   element = (
      //     <About
      //       open={true}
      //       setPage={this.setPage}
      //       handleDrawerOpen={this.handleDrawerOpen}
      //     ></About>
      //   );
      //   break;
      // default:
      //   element = null;
      default:
        element = (
          <Charters
            open={true}
            setPage={this.setPage}
            handleDrawerOpen={this.handleDrawerOpen}
          ></Charters>
        );
        break;
    }

    if (page != null) this.turnOffLoadingEffect();
    return element;
  };

  setPage = page => {
    if (page != this.state.navPage) this.setState({ navPage: page });
  };

  setViewState = state => {
    this.setState({ viewState: state });
  };

  render() {
    const { navDrawerOpen,viewState, navPage, playLoadingEffect } = this.state;

    return (
      <React.Fragment>
        <Router>
          {/* {playLoadingEffect ? <LoadingPage></LoadingPage> : null} */}
          {/* <NavDrawerV2
          viewState={viewState}
          page={this.state.navPage}
            open={navDrawerOpen || navPage}
            handleDrawerOpen={this.handleDrawerOpen}
          ></NavDrawerV2> */}
          <LandingV3
          
            setState={this.setViewState}
            handleDrawerOpen={this.handleDrawerOpen}
          ></LandingV3>

          <ComingSoon
            open={true}
            setPage={this.setPage}
            handleDrawerOpen={this.handleDrawerOpen}
          ></ComingSoon>
          {/* <Route exact path="/" component={() => this.getNavPage(null)} />
          <Route
            exact
            path="/courses"
            component={() => this.getNavPage("courses")}
          ></Route>
          <Route
            exact
            path="/lessons"
            component={() => this.getNavPage("lessons")}
          ></Route>
          <Route
            exact
            path="/charters"
            component={() => this.getNavPage("charters")}
          ></Route>
          <Route
            exact
            path="/about"
            component={() => this.getNavPage("about")}
          ></Route>
 */}
          <Route
            exact
            path="/contact"
            component={() => this.getNavPage("contact")}
          ></Route>
        </Router>
      </React.Fragment>
    );
  }
}

export default Home;
