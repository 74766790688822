import React from "react"
import { Grid, Hidden } from "@material-ui/core"

import ImageComponent from "../common/ImageComponent"
import AboutPagePhoto1 from "../../images/AboutPagePhoto1.jpg"
import HeroAndNav from "../common/SubPage/HeroAndNav"
import Boat from "../../images/Boat.png"
import MiddleSection from "../common/SubPage/MiddleSection"

export default function AboutTheLegacy(props) {
  const sectionTitle = "SAILING \n SINCE '67"
  const rightParagraphs = [
    "Generations of Whitings have carved a reputation through their achievements on the water. Whiting Sailing was established in 1967 by Penny Whiting, teaching the art of in the safety of the Waitemata Harbour. Since then, over 30000 people have learnt the practical skills and essential knowledge to be confident on the ocean.",
    "Now Penny’s son Carl brings a new energy, honed during a 20 year international sailing career. Whiting Sailing continues to be the only school that teach on a full-size yacht, developing a broader range of skills, and deeper understanding of sailing fundamentals.",
  ]
  const heroText =
    "Over 50 years experience shaping the future of sailing and developing confident New Zealand sailors."

  return (
    <React.Fragment>
      <HeroAndNav
        {...props}
        title={sectionTitle}
        heroImage={Boat}
        heroImageAlt="A high elevation photo of a lone yacht on a dark sea"
      />
      <MiddleSection rightParagraphs={rightParagraphs} heroText={heroText} />

      <Grid item xs={12} sm={1}></Grid>
      <Hidden smUp>
        <Grid style={{height: "5vh"}} xs={12}></Grid>
      </Hidden>
      <Grid item xs={12} sm={5}>
        <img style={{maxWidth:"100%"}} src={AboutPagePhoto1} alt={"AboutPagePhoto1"} alt="Penny Whiting and kids learning to sail aboard ‘Endless Summer’"/>
      </Grid>
      <Hidden xsDown>
        <Grid
          style={{
            display: "flex",
            alignItems: "right",
            marginTop: "52px",
          }}
          item
          xs={12}
          sm={6}
        >
          {" "}
          <ImageComponent style={{maxWidth:"100%"}} type={"svg"} url={"LookUpSeeChange"} />
        </Grid>
      </Hidden>
    </React.Fragment>
  )
}
