import React, { useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import { IconButton , ClickAwayListener} from "@material-ui/core";
import { Link } from "react-router-dom";

import BackToMainPageArrow from "../common/BackToMainPageArrow";
import ImageComponent from "../common/ImageComponent";
import NavStyles from "./Drawer.module.css";
import Logo from "../../images/WhiteLogo.svg";
import styled from "styled-components"
import mediaQuery from "../../utils/mediaQuery"


const StyledIconButton = styled(IconButton)`
 position: absolute;
  right: 1vw;
   top: 1vh ;


   ${mediaQuery.desktop} {
    right: 0.3%;
   top: 1vh ;


   }

  
   
`;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },

  menuButton: {
    // marginRight: theme.spacing(2),
    // float: "right",
  },
  hide: {
    display: "none"
  },
  drawer: {
    flexShrink: 0
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: "4s"
    }),
    marginRight: 0
  }
}));

export default function NavbarDrawerV2(props) {
  const Ref = useRef();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [main, setMain] = React.useState("");

  const handleDrawerOpen = () => {

    setOpen(true);
  };

  const navbarClose = async () => {
    if (main !== "") {
      Ref.current.handleDrawerClose();
    }
  };

  const navLinks = [
    {
      text: "sailing experiences",
      url: "/charters"
    },
    {
      text: "LEARN TO SAIL",
      url: "/courses"
    },
    {
      text: "PRIVATE LESSONS",
      url: "/lessons"
    },
    { text: "ABOUT", url: "/about" },
    {
      text: "CONTACT",
      url: "/contact"
    }
  ];
  const contactDetails = [
    {
      label: "address",
      value: (
        <div className={NavStyles.addressContainer}>
          <a
            className={NavStyles.address}
            href="https://www.google.com/maps/place/Westhaven+Marina/@-36.839578,174.744504,17z/data=!4m8!1m2!2m1!1sWesthaven+Marina!3m4!1s0x6d0d478b7aecc88f:0xe4049cdb5fe871a2!8m2!3d-36.84326!4d174.750382"
          >
            Emotional Rescue{"\n"}Westhaven Marina{"\n"}Auckland{"\n"}New
            Zealand
          </a>
        </div>
      )
    },
    {
      label: "email",
      value: (
        <a
          className={NavStyles.emailLink}
          href="mailto:info@whitingsailing.com"
        >
          info@whitingsailing.com
        </a>
      )
    },
    { label: "phone number", value: "+64 21 922 834" }
  ];
  const socialLinks = [
    { href: "https://www.instagram.com/whitingsailing/", text: "Instagram" },
    { href: "https://facebook.com/whitingsailing", text: "Facebook" }
  ];

  const NavHeader = () => (
    <>
      <div className={NavStyles.backButtonContainer}>
        <IconButton
          onClick={props.handleDrawerOpen}
          style={{ paddingLeft: "0px" }}
          className={[NavStyles.backButton, "overrideMui"]}
        >
          <BackToMainPageArrow color={"#FFFFFF"} />
        </IconButton>
      </div>
      <div style={{ width: "281px", height: "159px" }}>
        <img
          alt="Whiting Sailing School Logo"
          className={NavStyles.logo}
          src={Logo}
        />
      </div>
    </>
  );

  const Nav = ({ links }) => {
    return (
      <nav className={NavStyles.nav}>
        <ul className={NavStyles.navlist}>
          {links.map(link => (
            <li className={NavStyles.navlink} key={link.url}>
              <Link className={NavStyles.navlink} to={link.url}>
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  const ContactDetails = ({ details }) => {
    return (
      <dl className={NavStyles.contactDetails}>
        {details.map(detail => (
          <address>
            <dt className={NavStyles.contactDetailLabel}>{detail.label}</dt>
            <dd
              className={`${NavStyles.contactDetail} ${detail.classes || ""}`}
            >
              {detail.value}
            </dd>
          </address>
        ))}
      </dl>
    );
  };

  const SocialLinks = ({ links }) => (
    <div className={NavStyles.socialLinks}>
      {links.map(link => (
        <a href={link.href} rel="noopener"  className={NavStyles.socialLink}>
          {link.text}
        </a>
      ))}
    </div>
  );


  
  const BrandTagline = () => (
    <div className={NavStyles.brandPhraseContainer}>
      <p className={NavStyles.brandPhrase}>
        SEE{"\n"}CHANGE{"\n"}
      </p>
      <ImageComponent
        className={NavStyles.brandPhraseSeparator}
        url={"Triangle"}
        type={"svg"}
        alt="Triangle separator"
      />
      <p className={NavStyles.brandPhrase}>
        SEA{"\n"}CHANGE{"\n"}
      </p>
    </div>
  );


  
  return (<>
    <div onClick={() => {


      if(!props.page && props.open) { 

props.handleDrawerOpen();
      }
 
    }} style={{  position: 'fixed', width: "5%", top: 0, left: 0, opacity: 0, height: '100vh'}}>x</div>
    <div style={{ float: 'right', height: "60px" }}>
      
<StyledIconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={clsx(classes.menuButton, props.open)}
      
      >
        <MenuIcon style={{ fontSize: "2rem", color: "rgb(250, 70, 22)" }} />
      </StyledIconButton>
      
      <Drawer
        transitionDuration={800}
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={props.open}
        classes={{
          paper: NavStyles.drawerPaper
        }}
        onClick={navbarClose}
      >
        <Hidden only={["xs", "lg"]}>
          <ImageComponent
            style={{
              position: "absolute",
              left: "167px",
              top: "88px",
              zIndex: "-1"
            }}
            url={"Menu-Boats"}
            type={"jpg"}
          />
        </Hidden>
        <Hidden mdDown>
          <ImageComponent
            style={{
              position: "absolute",
              right: "0px",
              top: "88px",
              zIndex: "-1"
            }}
            url={"Menu-Boats"}
            type={"jpg"}
          />
        </Hidden>
        <div className={NavStyles.drawerContainer}>
          <div className={NavStyles.navContainer}>
            <NavHeader />
            <div className={NavStyles.navBody}>
              <Nav links={navLinks} />
              <ContactDetails details={contactDetails} />
            </div>
            <SocialLinks links={socialLinks} />
          </div>
          <Hidden only={["xs", "sm", "md"]}>
            <BrandTagline />
          </Hidden>
        </div>
      </Drawer>
    </div>
    </>
  );
}
