import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Styles from "./styles.module.css";

const CompassButton = withStyles(theme => ({
  root: {
    "&:hover": {
      background: "none"
    },
    color: "red",
    fontSize: "14px",
    fontFamily: "sofia-pro"
  }
}))(Button);

export default function CompassSelectorArrowDirection(props) {
  const rotateTopLeft = () => {
    var compassArrow = document.getElementById("compassArrow");
    compassArrow.style.transformOrigin = "top right";
    compassArrow.style.transform = "rotate(90deg)";
  };
  const rotateBottomRight = () => {
    var compassArrow = document.getElementById("compassArrow");
    compassArrow.style.transformOrigin = "top right";
    compassArrow.style.transform = "rotate(270deg)";
  };
  const rotateBottomLeft = () => {
    var compassArrow = document.getElementById("compassArrow");
    compassArrow.style.transformOrigin = "top right";
    compassArrow.style.transform = "rotate(360deg)";
  };
  const rotateTopRight = () => {
    var compassArrow = document.getElementById("compassArrow");
    compassArrow.style.transformOrigin = "top right";
    compassArrow.style.transform = "rotate(180deg)";
  };
  return (
    <div className={Styles.compass}>
      <div
        style={{
          width: "50%",
          height: "50%",
          float: "left"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
          }}
        >
          <CompassButton
            onMouseOver={rotateTopLeft}
            onClick={() => {
              props.handleCompassSelector(props.subPages[0]);
            }}
          >
            {props.subPages[0]}
          </CompassButton>
        </div>
      </div>
      <div
        style={{
          borderLeft: "2px solid red",
          width: "50%",
          height: "50%",
          float: "right"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
          }}
        >
          {props.subPages[1].length !== 0 ? (
            <CompassButton
              style={{
                marginLeft: "20px"
              }}
              onMouseOver={rotateTopRight}
              onClick={() => {
                props.handleCompassSelector(props.subPages[1]);
              }}
            >
              {props.subPages[1]}
            </CompassButton>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        style={{
          borderTop: "2px solid red",
          width: "50%",
          height: "50%",
          float: "left"
        }}
      >
        <img
          id="compassArrow"
          style={{
            transformOrigin: "top right",
            transform: "rotate(90deg)",
            float: "right",
            marginBottom: "0px"
          }}
          src={require("../../images/" + props.arrowColor + ".svg")}
          alt={"Compass arrow"}
        />
        {props.subPages[2].length !== 0 ? (
          <CompassButton
            style={{
              marginLeft: "20px"
            }}
            onMouseOver={rotateBottomLeft}
            onClick={() => {
              props.handleCompassSelector(props.subPages[2]);
            }}
          >
            {props.subPages[2]}
          </CompassButton>
        ) : (
          ""
        )}
      </div>
      <div
        style={{
          borderTop: "2px solid red",
          borderLeft: "2px solid red",
          width: "50%",
          height: "50%",
          float: "right"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
          }}
        >
          {props.subPages[3].length !== 0 ? (
            <CompassButton
              style={{
                marginLeft: "20px"
              }}
              onMouseOver={rotateBottomRight}
              onClick={() => {
                props.handleCompassSelector(props.subPages[3]);
              }}
            >
              {props.subPages[3]}
            </CompassButton>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
