import React, { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import HomeBannerLayout from "./HomeBannerLayout";
import ImageComponent from "../common/ImageComponent";
import Styles from "./styles.module.css";
import HomeInfoBlock from "./HomeInfoBlock";
import Bullet from "../../images/HomeBullet.svg";
import PrimaryButton from "../common/PrimaryButton";
import Content from "./home.json";
import BigW from "../../images/bigW.svg";
import DesktopBackgroundVideo from "../../videos/desktop.mp4";
import MobileBackgroundVideo from "../../videos/mobile.mp4";
import SailingExperienceBg from "../../images/HomeBackgroundExperience.jpg";
import LearnToSailBg from "../../images/HomeBackgroundLearnToSail.jpg";
import PrivateLessonsBg from "../../images/HomeBackgroundPrivateLessons.jpg";

export default function LandingV2(props) {
  const content = Content;
  const [viewState, setViewState] = useState(0);

  const setActive = (index, e) => {
    props.setState(index);
    setViewState(index);

    content[index].active = true;

    content.forEach(function(e, i) {
      if (i != index) {
        e.active = false;
      }
    });
  };

  function OnMenuClick(e, index) {
    setActive(index, e);
    var videoList = document.getElementsByTagName("video");
    for (let video of videoList) {
      video.currentTime = index * 8;
      switch (index) {
        case 0:
          video.poster = SailingExperienceBg;
          break;
        case 1:
          video.poster = LearnToSailBg;
          break;
        case 2:
          video.poster = PrivateLessonsBg;
          break;
      }
    }
  }

  const MenuLink = (link, index) => (
    <li onClick={e => OnMenuClick(e, index)}>
      <Grid container>
        <Grid item xs={11}>
          {link}{" "}
        </Grid>
        <Grid item xs={1}>
          <img
            src={Bullet}
            className={
              viewState === index
                ? Styles.bulletPoint
                : Styles.bulletPointHidden
            }
            alt={"Main menu selection bullet"}
          />
        </Grid>
      </Grid>
    </li>
  );

  const MenuLinks = ({ links }) => (
    <Grid style={{ display: "flex" }} item xs={3}>
      <ul className={Styles.homeList}>
        {links.map((link, i) => MenuLink(link, i))}
      </ul>
    </Grid>
  );

  const MenuLinksLg = ({ links }) => (
    <Grid
      className={Styles.textContainer}
      container
      xs={12}
      align-content-xs-flex-end
    >
      <Grid item xs={12} justify-xs-flex-end>
        <ul className={Styles.homeList}>
          {links.map((link, i) => MenuLink(link, i))}
        </ul>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Hidden mdDown>
        <video autoPlay loop muted poster={SailingExperienceBg}>
          <source src={DesktopBackgroundVideo} type="video/mp4" />
        </video>
      </Hidden>
      <Hidden smUp>
        <video autoPlay loop muted poster={SailingExperienceBg}>
          <source src={MobileBackgroundVideo} type="video/mp4" />
        </video>
      </Hidden>
      <Hidden only={["xs", "lg", "xl"]}>
        <video autoPlay loop muted poster={SailingExperienceBg}>
          <source src={MobileBackgroundVideo} type="video/mp4" />
        </video>
      </Hidden>
    </>
  );
}