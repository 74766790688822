import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import BackToMainPageArrow from "../common/BackToMainPageArrow";
import SubMenuTitle from "../common/SubMenuTitle";
import CompassSelector from "../common/CompassSelector";
import PrimaryButton from "../common/PrimaryButton";
import BoatInfo from "../boat-info/BoatInfo";
import SubMenuInfo from "../common/SubMenuInfo";
import TheBoatsPagePhoto1 from "../../images/TheBoatsPagePhoto1.jpg";
import EmotionalRescue from "../../images/EmotionalRescue.jpg";
import EndlessSummer from "../../images/EndlessSummer.jpg";
import Styles from "../common/styles.module.css";
import HeroAndNav from "../common/SubPage/HeroAndNav";
import MiddleSection from "../common/SubPage/MiddleSection";
import { Hidden } from "@material-ui/core";

export default function AboutTheBoats(props) {
  useEffect(() => {
    var imageList = [TheBoatsPagePhoto1];
    imageList.forEach(image => {
      new Image().src = image;
    });
  });

  const rightParagraphs = [
    "Whiting Sailing operates two of the most iconic keel boats on the Waitemata, each with their own legend, growing with each adventure."
  ];
  const heroText =
    "In sailing certain boats are legend, whether it’s races they have sailed, oceans endured or the defining experiences crews have had.";
  const buttonTitle = "SEE THE SCHEDULE";
  const buttonSubTitle = "Charter a boat today";

  return (
    <Grid container>
      <HeroAndNav
        {...props}
        title={"COMPANY OF \n SAILS"}
        heroImage={TheBoatsPagePhoto1}
        heroImageAlt="Image of 3 yachts and bean rock lighthouse on the Auckland harbour. The sky tower is visible in the distance"
      />
      <MiddleSection
        rightParagraphs={rightParagraphs}
        heroText={heroText}
        buttonTitle={buttonTitle}
        buttonSubTitle={buttonSubTitle}
      />
      <Grid container>
        <Grid item xs={12} sm={1}></Grid>
        <BoatInfo
          boatImage={EmotionalRescue}
          boatAlt={"Photo of Emotional Rescue at sea"}
          title={"Emotional Rescue\n"}
          boatInfoParagraphs={["A Davidson 55, built in the 90’s as the ultimate balance of Offshore Racing capability with cruising comfort, she has competed in some of the biggest international events such as Hawaii’s Kenwood Cup.",
          "Her stable and practical layout makes her an ultimate boat for people not only to learn to sail on but to develop further to advanced and club racing levels."]}
          specs={{ length: "55FT", built: "1992", origin: "New Zealand" }}
        />
        <Hidden only={["xs"]}>
          <Grid sm={1}></Grid>
        </Hidden>
        <BoatInfo
          boatImage={EndlessSummer}
          boatAlt={"Photo of Endless Summer at Sea"}
          title={"Endless Summer\n"}
          boatInfoParagraphs={["Endless Summer has been the first love of many a novice sailor. She is a Whiting 47, a design chosen for its versatility and purpose built around Penny's teaching experiences.",
          "The layout ensures that she is equally suitable to be crewed by novices or those seeking to develop their existing skillset."]}
          specs={{ length: "55FT", built: "1992", origin: "New Zealand" }}
        />
      </Grid>
    </Grid>
  );
}
