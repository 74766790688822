import React from "react";
import { IconButton, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    height: "45px",
    "&:hover": {
      background: "#fa4616"
    }
  }
}));

export default function BackToMainPageArrow(props) {
  const [arrowColor, setArrowColor] = React.useState(props.color);

  const classes = useStyles();

  const changeArrowColor = () => {
    setArrowColor("#FFFFFF");
  };

  const changeArrowColor1 = () => {
    if (props.color !== "#FFFFFF") setArrowColor("#FF3302");
  };

  return (
    <IconButton
      className={classes.root}
      onClick={props.clickEvent}
      onMouseOver={changeArrowColor}
      onMouseLeave={changeArrowColor1}
    >
      <svg
        width="25"
        height="11"
        viewBox="0 0 25 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 7V11L0 5.13786L13 0V4L23.5 4C24.3284 4 25 4.67157 25 5.5C25 6.32843 24.3284 7 23.5 7L13 7Z"
          fill={arrowColor}
        />
      </svg>
    </IconButton>
  );
}
