import React, { Component } from "react";
import { Drawer, Hidden, ClickAwayListener } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import HamburgerBack from "../common/HamburgerBack";
import Styles from "./styles.module.css";

const styles = makeStyles(theme => ({
  hide: {
    display: "none"
  },
  drawer: {
    width: "96%",
    flexShrink: 0
  },
  drawerPaper: {
    background: "#EFEEE8"
  }
}));

class NavPage extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleDrawerClose = () => {
    this.props.handleDrawerOpen(true);
    this.setState({ open: false });
    this.props.history.push("/");
  };

  componentDidMount() {
    this.setState({ open: true });
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  render() {
    return (
      <ClickAwayListener onClickAway={this.props.handleDrawerOpen}>
        <Drawer
          anchor="right"
          open={this.props.open}
          transitionDuration={800}
          variant="persistent"
          classes={{
            paper: this.props.classes
              ? this.props.classes.drawerPaper
              : Styles.drawerPaper
          }}
        >
          <Grid container>
            <Hidden xsDown>
              <HamburgerBack
                handleDrawerClose={this.props.handleDrawerOpen}
                style={
                  this.props.location.pathname == "/contact"
                    ? { backgroundColor: "#FFC1B3" }
                    : null
                }
              />
            </Hidden>
            <Hidden smUp>
              <HamburgerBack
                handleDrawerClose={this.props.handleDrawerOpen}
                style={
                  this.props.location.pathname == "/contact"
                    ? { backgroundColor: "#FFC1B3" }
                    : null
                }
                height="0px"
              />
            </Hidden>
            {this.props.children}
          </Grid>
        </Drawer>
      </ClickAwayListener>
    );
  }
}

export default withRouter(NavPage);
