import React from "react";
import Styles from "./styles.module.css";

export default class LoadingEffect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0
    };
  }

  componentDidMount() {
    this.setWindowSize();
  }

  setWindowSize = () => {
    this.setState({ window: window.screen.width });
  };

  render() {
    return (
      <div className={Styles.container}>
        <div style={{ width: `${this.state.window}px` }}></div>
        <span className={Styles.loadingEffect}></span>
      </div>
    );
  }
}
