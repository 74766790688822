import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

// TODO: Structure props better and link with primsic SEO Pages.

/**
 * SEO Component. Should be render after the Layout of every page to render the meta.
 * @param {description} seo description
 * @param {lang} lang default en
 *  @param {title} lang lang
 *  @param {image} Image Url
 */

const SEO = ({ description, lang, title, image }) => {
  const meta = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },

    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },

    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ]

  if (image) {
    meta.push({
      property: `og:image`,
      content: `${process.env.REACT_APP_BASE_URL}${image}`,
    })

    meta.push({
      name: `twitter:image`,
      content: `${process.env.REACT_APP_BASE_URL}${image}`,
    })
  } else {
    meta.push({
      property: `og:image`,
      content: `${process.env.REACT_APP_BASE_URL}/whiting-sailling-newzealand.png`,
    })

    meta.push({
      name: `twitter:image`,
      content: `${process.env.REACT_APP_BASE_URL}/whiting-sailling-newzealand.png`,
    })

  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={meta}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  // eslint-disable-next-line react/default-props-match-prop-types
  description: ``,
}


SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  image: PropTypes.string,
  title: PropTypes.string,
}

export default SEO
