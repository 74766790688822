import React, { Component } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton, Grid, Hidden } from "@material-ui/core";

class HamburgerBack extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid
        container
        item
        xs={12}
        style={{
          height: this.props.height || "60px",
          position: "relative",
          ...this.props.style
        }}
      >
        <Grid item xs={12}>
          <IconButton
            onClick={this.props.handleDrawerClose}
            color="inherit"
            aria-label="close drawer"
            style={{
              position: "absolute",
              right: "1vw",
              top: "1vh",
              float: "right",
              zIndex: 100,
              opacity: 0
            }}
          >
            <MenuIcon style={{ color: "rgb(250, 70, 22)" }} />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
}

export default HamburgerBack;
