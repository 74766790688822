import React from "react";
import { Grid, Hidden } from "@material-ui/core";

import BackToMainPageArrow from "../BackToMainPageArrow";
import SubMenuTitle from "../SubMenuTitle";
import CompassSelector from "../CompassSelector";
import Styles from "./styles.module.css";

export default function HeroAndNav(props) {
  const sectionTitle = props.title;
  const heroImage = props.heroImage;
  const heroImageAlt = props.heroImageAlt;
  const topImage = props.topImage;
  const topImageAlt = props.topImageAlt;
  const imgElement = React.useRef(null);
  const [imgHeight, setImgHeight] = React.useState("");

  const TitleGrid = ({ style }) => (
    <Grid item xs={12} style={style}>
      <SubMenuTitle title={sectionTitle} />
    </Grid>
  );

  return (
    <Grid container style={{ minHeight: "50vh" }}>
      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={4} md={5}>
        <Hidden mdDown>
          {" "}
          <Grid item xs={12}>
            <BackToMainPageArrow
              clickEvent={props.handleDrawerClose}
              color={"#FF3302"}
            />
          </Grid>
        </Hidden>
        <Hidden only={["xs", "lg", "xl"]}>
          {" "}
          <Grid item xs={12} style={{ marginBottom: "40px" }}>
            <BackToMainPageArrow
              clickEvent={props.handleDrawerClose}
              color={"#FF3302"}
            />
          </Grid>
        </Hidden>
        <Hidden only={["xs", "lg", "xl"]}>
          <TitleGrid style={{ position: "absolute", zIndex: "1" }} />
        </Hidden>
        <Hidden smUp>
          <TitleGrid
            style={{
              position: "absolute",
              zIndex: "1",
              height: imgHeight,
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              paddingLeft: "2vw",
              paddingBottom: "2vh"
            }}
          />
        </Hidden>
        {props.useCompass === true ? (
          <Hidden only={["xs"]}>
            <Grid item xs={12}>
              <CompassSelector
                handleCompassSelector={props.handleCompassSelector}
                componentName={props.componentName}
                className={Styles.compass}
                subPages={props.pageLinks}
                arrowColor={props.arrowColor}
              />
            </Grid>
          </Hidden>
        ) : (
          <Hidden only={"xs"}>
            <Grid item xs={12}>
              <img
                src={topImage}
                alt={topImageAlt}
                style={{ position: "absolute", marginTop: "20vh" }}
              />
            </Grid>
          </Hidden>
        )}
        <Hidden mdDown>
          <TitleGrid />
        </Hidden>
      </Grid>
      <Grid item xs={12} sm={7} md={6}>
        <div style={{ display: "flex" }}>
          <img
            className={Styles.heroImageContainer}
            src={heroImage}
            alt={heroImageAlt}
            ref={imgElement}
            style={{marginRight: 0, marginLeft: "auto"}}
            onLoad={() => setImgHeight(imgElement.current.naturalHeight)}
          />
        </div>
      </Grid>
      {props.useCompass === true ? (
        <Hidden smUp>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <CompassSelector
              handleCompassSelector={props.handleCompassSelector}
              componentName={props.componentName}
              className={Styles.compass}
              subPages={props.pageLinks}
              arrowColor={props.arrowColor}
            />
          </Grid>
        </Hidden>
      ) : (
        <></>
      )}
      <Grid item xs={12} style={{ height: "52px" }}></Grid>
      <Grid item xs={12} sm={1}></Grid>
    </Grid>
  );
}
