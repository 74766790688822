import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Hidden } from "@material-ui/core";

import PrimaryButton from "../common/PrimaryButton";
import SubMenuTitle from "../common/SubMenuTitle";
import NavPage from "../nav/NavPage";
import SEO from "../seo/seo";
import ChartersCharters from "./ChartersCharters";
import ChartersTheBoats from "./ChartersTheBoats";
import Styles from "./coming-soon.module.css";
import { Link } from "react-router-dom";

const chartersPages = ["Charters", "The Boats", "", ""];

class Charters extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, compassValue: chartersPages[0] };
  }

  componentDidMount() {
    this.setState({ open: true });
  }

  handleDrawerClose = () => {
    this.setState({ open: false });

    setTimeout(() => {
      this.props.history.push("/");
    }, 801);
  };

  handleCompassSelector = value => {
    this.setState({ compassValue: value });
  };

  render() {
    return (
      <NavPage
        open={this.state.open}
        // handleDrawerOpen={this.handleDrawerClose}
        classes={Styles}
      >
        <SEO 
        title="Our Boats | Whiting Sailing | Sail in New Zealand"
        description="A personal off-shore sailing experience built for you to go beyond the everyday and discover parts of New Zealand few others see.">
      
        </SEO>

        <Grid container style={{ minHeight: "50vh" }}>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={10} md={7}>
            <SubMenuTitle title="New sailing programs launching soon" />
            <div className={Styles.contactWrap}>
              <a href="/contact" >
                <PrimaryButton
                  title={"Get in touch"}
                />
              </a>
              <a href="https://www.learntosailnz.com">
                <PrimaryButton
                  title={"Learn to sail"}
                />
              </a>
            </div>
          </Grid>
        </Grid>

      </NavPage>
    );
  }
}

export default withRouter(Charters);
