import React from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import BackToMainPageArrow from "../common/BackToMainPageArrow";
import Form from "./ContactForm";
import Styles from "./styles.module.css";

export default function ContactMain(props) {
  const socialLinks = [
    { href: "https://www.instagram.com/whitingsailing/", text: "Instagram" },
    { href: "https://facebook.com/whitingsailing", text: "Facebook" }
  ];

  const SocialLinks = ({ links }) => (
    <div className={Styles.socialLinks}>
      <Grid container>
        <Grid item xs={5} lg={12}>
          <a href={links[0].href} taget="_blank"  className={Styles.socialLink}>
            {links[0].text}
          </a>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={2}></Grid>
        </Hidden>
        <Grid item xs={5} lg={12}>
          <a href={links[1].href} className={Styles.socialLink}>
            {links[1].text}
          </a>
        </Grid>
      </Grid>
    </div>
  );

  const PageTitle = ({ title }) => <h1>{title}</h1>;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ backgroundColor: "#FFC1B3", paddingBottom: "40px" }}
      >
        <Grid container>
          <Hidden only="xs">
            <Grid item xs={1}></Grid>
            <Grid item xs={11} style={{ marginBottom: "5vh" }}>
              <div style={{ paddingTop: "30px" }}>
              </div>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={6} md={5} lg={5}>
            <Hidden smUp>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "bottom",
                  height: "280px",
                  paddingLeft: "5vw"
                }}
              >
                <Hidden lgUp>
                  <PageTitle title={["MESSAGE IN A", <br />, "BOTTLE"]} />
                </Hidden>
                <Hidden mdDown>
                  <PageTitle title={["MESSAGE IN", <br />, " A BOTTLE"]} />
                </Hidden>
              </div>
            </Hidden>
            <Hidden only={["xs"]}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "0.5vh"
                }}
              >
                <Hidden lgUp>
                  <PageTitle title={["MESSAGE IN A", <br />, "BOTTLE"]} />
                </Hidden>
                <Hidden mdDown>
                  <PageTitle title={["MESSAGE IN", <br />, " A BOTTLE"]} />
                </Hidden>
              </div>
            </Hidden>
          </Grid>
          <Hidden only={["xs", "sm", "md"]}>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <SocialLinks links={socialLinks} />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={4}
            md={5}
            lg={2}
            className={Styles.contactGrid}
          >
            <address>
              <a  style={{textDecoration: 'none'}} target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Westhaven+Marina/@-36.839578,174.744504,17z/data=!4m8!1m2!2m1!1sWesthaven+Marina!3m4!1s0x6d0d478b7aecc88f:0xe4049cdb5fe871a2!8m2!3d-36.84326!4d174.750382"><div className={Styles.address}>
                Emotional Rescue <br />
                Westhaven Marina <br />
                Auckland <br />
                New Zealand
              </div>
              </a>
              <div className={Styles.contact}><a href="mailto:info@whitingsailing.com" className={Styles.contact}>info@whitingsailing.com</a></div>
              <div className={Styles.contact}><a href="tel:+6421922834" className={Styles.contact} >+64 21 922 834</a></div>
            </address>
          </Grid>
          <Hidden only={["xs", "lg", "xl"]}>
            <Grid item xs={7}></Grid>
            <Grid item xs={5}>
              <SocialLinks className={Styles.contact} links={socialLinks} />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12}>
              <SocialLinks className={Styles.contact} links={socialLinks} />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={11} sm={11} md={11} lg={4}>
        <Hidden only={["xs", "sm", "md"]}>
          <div className={Styles.formDesc}>
            Leave us a message{"\n"}and one of our crew will{"\n"}come back to
            you
          </div>
        </Hidden>

        <Hidden only={["lg", "xl"]}>
          <div className={Styles.formDesc}>
            Leave us a message and one of {"\n"} our crew will come back to you.
          </div>
        </Hidden>
      </Grid>
      <Hidden lgUp>
        <Grid item xs={1}></Grid>
      </Hidden>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "52px",
          marginBottom: "50px"
        }}
        item
        xs={10}
        md={11}
        lg={7}
      >
        <div style={{ width: "424px" }}>
          <Form />
        </div>
      </Grid>
      <Hidden smUp>
        <Grid item xs={1}></Grid>
      </Hidden>{" "}
    </Grid>
  );
}
